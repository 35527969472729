import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Icon,
  Image,
  HStack,
  Badge,
  useDisclosure,
  Container,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Grid,
  GridItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Center,
  Wrap,
  Link,
} from "@chakra-ui/react";
import { useRef } from "react";
import ContactForm from "../components/contactform";
import DevOpsSection from "../components/devops";
import BackstageOpsSection from "../components/backstage";
import CloudOpsCard from "../components/cloudops card";
import MenuBar from "../components/menu";
import { EmailIcon } from "@chakra-ui/icons";
import { FaMailBulk } from 'react-icons/fa';


const LandingPage: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(window);
  const goHome = () => {
    const el = document.getElementById("test");
    el?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box background={"transparent"} w="100%">
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1rem"
        bg="gray.800"
        overflow={"visible"}
      >
        <Heading as="span" size="lg" color="blue.100" letterSpacing={"-.1rem"}>
          null
          <Heading as="span" size="lg" color="blue.500">
            Rabbit
          </Heading>
          <Text
            color="white"
            fontSize="xs"
            fontStyle={"normal"}
            fontWeight={"2"}
            letterSpacing={".1rem"}
          >
           Cloud Consulting Services & Investment
          </Text>
        </Heading>
        <Button leftIcon={<EmailIcon/>} iconSpacing={5} color="blue.100" variant="outline" onClick={onOpen}>
          Contact Us
        </Button>
      </Flex>

      <Flex
        align="center"
        justify="center"
        minH="80vh"
        bg="gray.900"
        justifyContent={"center"}
      >
        <Stack spacing={8} minW="sm" maxW="lg" textAlign="center">
          <Heading size="4xl" textColor="white">
            Harness the{" "}
            <Text as="span" color="blue.500">
              Chaos
            </Text>
          </Heading>
          <Image
            objectFit={"contain"}
            height="200"
            src="rabbit_entropy_100x144.png"
          ></Image>
          <Text fontSize="2xl" color="blue.100">
            We provide boutique consulting services hyper-focused on 
            solutions that accelerate your Platform Engineering, Developer
            and Cloud Teams. 
          </Text>
        </Stack>
      </Flex>
      <Container
        bg="gray.800"
        pt={10}
        maxW="container.4xl"
        alignContent={"center"}
      >
               
      </Container>
      <Text id="test"></Text>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ContactForm props={{ onClose }} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LandingPage;

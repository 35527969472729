import { EmailIcon } from "@chakra-ui/icons";
import { Button, Checkbox, CheckboxGroup, CloseButton, Container, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, Spacer, Text,Textarea, VStack, Wrap, useToast } from "@chakra-ui/react"
import  axios  from "axios";
import { useState } from "react";
import { MdEmail } from "react-icons/md";
import { setOriginalNode } from "typescript";

function postContact(modal:any) {
    const API_ENDPOINT = "https://jbjvhwyyni.execute-api.us-west-2.amazonaws.com/dev/contact"
    axios.post(API_ENDPOINT, {"test": "fly"})
    .then( (resp)=> {
        console.log("Successs")
        console.log(resp)
        //modal.props.onClose();
    })
    .catch((error) => {
        console.log(error)
    })

}
const ContactForm = (props: any) => {
    const [ formObj, setFormObj] = useState({email:'', company:'', name: '', interest: '', other: false, investment: false, portal: false, consulting: false, training: false})
    const API_ENDPOINT = "https://jbjvhwyyni.execute-api.us-west-2.amazonaws.com/dev/contact"
    const toast = useToast()
    return (
        <Container rounded="lg" bgColor="white.200" padding={5}>
        <Heading   pb={5} size="md">How can we help?</Heading>
        <FormControl>
            <FormLabel as="legend">Your Name</FormLabel>
            <Input type="text" onChange={(e) => setFormObj({...formObj, name: e.target.value})}></Input>
            <FormLabel pt={3} as="legend">Company</FormLabel>
            <Input type="text" onChange={(e) => setFormObj({...formObj, company: e.target.value})}/>
            <FormLabel pt={3} textTransform={'capitalize'}>Email Address</FormLabel>
            <Input isRequired type="email" onChange={(e) => setFormObj({...formObj, email: e.target.value})}/>
            <Flex pt={3}> 
            <Textarea textColor="white" placeholder="What can we help you with?" onChange={(e) => setFormObj({...formObj, interest: e.target.value})}/>
            </Flex>
            <FormControl as="fieldset">
                <FormLabel pt={3} as="legend" fontSize="lg">
                    What are you interested in?
                </FormLabel>
               <VStack align={'left'}>
                <CheckboxGroup>
                    <Checkbox onChange={(e) => setFormObj({...formObj, consulting: e.target.checked})}>Cloud Consulting Services</Checkbox>
                    <Checkbox onChange={(e) => setFormObj({...formObj, investment: e.target.checked})}>Looking for Investors</Checkbox>
                    <Checkbox onChange={(e) => setFormObj({...formObj, other: e.target.checked})}>Other</Checkbox>
                </CheckboxGroup>
                </VStack>
            </FormControl>
            <Wrap justify="center" py={3} pt={5}>
            <Button  py={5} leftIcon={<MdEmail/>} colorScheme="telegram" onClick={() => {
                const submitForm = axios.post(API_ENDPOINT, formObj)
                toast.promise(submitForm, {
                    success: {position: "top", title: "Success! We saved your request!", description: "Thanks! If requested, we'll reach out to you soon!", isClosable: true},
                    error: {title: "Error", description: "We had trouble saving the data, please try again", isClosable: true},
                    loading: {title: 'Loading', description: "pending..", isClosable: true}
                });
                props.props.onClose()
                
            }}>Send in Information</Button><Button variant="outline" colorScheme="orange" onClick={props.props.onClose}>Cancel</Button>
            </Wrap>
        </FormControl>
        </Container>
    )
}

export default ContactForm;